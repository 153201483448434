<template>
  <TransitionExpand>
    <div v-if="i18nData" class="wrapper alert alert-danger my-3">
      <div role="alert" class="with-icon mb-0">
        <font-awesome-icon icon="exclamation-circle" size="2x" class="danger-color" />
        <div class="ml-3">{{ $t(i18nData.key, i18nData.values) }}</div>
      </div>
      <div v-if="showReload" class="d-flex justify-content-center">
        <b-button
          class="btn btn-primary mt-2"
          type="submit"
          variant="primary"
          @click="reloadSession"
        >
          <strong>{{ $t('pages.login.reloadSession') }}</strong>
        </b-button>
      </div>
    </div>
  </TransitionExpand>
</template>

<script>
import TransitionExpand from '@/elements/TransitionExpand'
import {
  getProductName
} from '@/utils/url'
export default {
  name: 'Alert',
  props: {
    i18nData: {
      type: Object
    }
  },
  components: {
    TransitionExpand
  },
  computed: {
    productName () {
      return getProductName()
    },
    showReload () {
      return this.isBadUserError && (this.productName !== 'mtalk-app' && !document.hidden)
    },
    isBadUserError () {
      const errorKeys = [
        'pages.login.errorBadUserReseller',
        'pages.login.errorBadUserLyber',
        'pages.login.errorBadUserMnet'
      ]
      return errorKeys.includes(this.i18nData.key)
    }
  },
  methods: {
    reloadSession () {
      location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
.with-icon {
  display: flex;
  align-items: center;
}
.danger-color {
  color: $danger;
}
</style>
